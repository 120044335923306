import { useEffect, useRef, useState } from 'react'
import { Box, TextField, IconButton, CircularProgress, Grid, Typography, Chip, useTheme, useMediaQuery, Tooltip } from '@mui/material';
import { useChatCompletion } from './actions';
import { ArrowUpward, Clear, UploadFile } from '@mui/icons-material';
import ChatMessage from './ChatMessage';
import SourcesModal from './SourcesModal';
import { doubleGradient } from '../../Constants/Colors';
import ReadMore from "../general/ReadMore";
import AppBar from '../general/AppBar';
import { Anta, fontSizes } from "../../Constants/Fonts";
import { ipsum } from "../../Constants/ipsum";
import { useLocation } from 'react-router';
import { dummyExperts, testEmails } from '../../Constants/DummyInfo';
import { useQueryClient } from '@tanstack/react-query';
import RecordButton from '../general/RecordButton';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { getUserEmail, makeAuthenticatedRequest } from '../../util/Firebase';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import CustomButton from '../general/CustomButton';
import DownloadChatButton from './DownloadChatButton';
import AdminOnly from '../general/AdminOnly';
import BackgroundTemplate from '../general/BackgroundTemplate';
import logo from '../../assets/images/logo.png'
import TextToSpeechPlayer from '../general/TextToSpeechPlayer';

const dummyPitches = [
  "10+ yrs Experience",
  "Worked at Crowdstrike",
  "CISSP Member",
  "CompTIA Pentesting Certified",
  "Wrote Linux Kernel"
]

const ExpertDisp = ({ info = {}, onClick }) => {
  const theme = useTheme()
  const pitches = info.pitches || dummyPitches;
  
  return (
      <Grid container sx={{ flexDirection: 'column', padding:'1rem'}}>
          <Grid item sx={{ width: "100%", maxWidth: 200, display: { xs: 'none', md: 'flex' } }}>
              <img
                  src={info.image || require('../../assets/images/john_doe.jpg')}
                  alt="Expert"
                  style={{ width: '100%', borderRadius:12 }}
              />
          </Grid>
          <Box>
            <Typography sx={{fontSize: fontSizes.defaultSize+4}}>{info.name || "John Doe"}</Typography>
            <Typography sx={{fontSize: fontSizes.defaultSize+2}}>{info.title || "Cybersec Specalist"}</Typography>
          </Box>
          {/* <Grid item container sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingLeft:'1rem' }} md={9}>
              <Grid container sx={{display: 'flex', flexDirection: 'column', maxWidth:'100%', flex:1}} rowGap={1}>
                  <Box>
                  <Typography sx={{fontSize: fontSizes.defaultSize+4}}>{info.name || "John Doe"}</Typography>
                  <Typography sx={{fontSize: fontSizes.defaultSize+2}}>{info.title || "Cybersec Specalist"}</Typography>
                  </Box>
                  <Grid conainer sx={{ display: 'flex',flexWrap:'wrap', maxWidth:'100%' }} gap={1}>
                      {
                          pitches.map((p, index) => (
                              <Grid item>
                                  <Chip label={p} key={`chip_${index}`}/>
                              </Grid>
                          ))
                      }
                  </Grid>
                  <ReadMore text={info.about || ipsum}/>
              </Grid>
          </Grid> */}
      </Grid>

  );
};

function ChatBot() {
  const inputFileRef = useRef(null);
  const location = useLocation()
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isSmAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const queryParams = new URLSearchParams(location.search)
  const expertId = queryParams.get('expertid');
  const [expert, setExpert] = useState(location?.state?.expert || dummyExperts[0])
  const expertBackendId = dummyExperts.find((e) => e.id == expertId)?.backendId ?? expert.id;
  const [submitAudio, setSubmitAudio] = useState(false)
  const [audioMuted, setAduioMuted] = useState(false)
  const [textToTranscribe, setTextToTranscribe] = useState()
  const autoPlay = useRef(false)

  const [sourcesOpen, setSourcesOpen] = useState(false);
  const [prompt, setPrompt] = useState("");
  const messagesEndRef = useRef(null);
  const [mutate, { data, isPending }] = useChatCompletion(expertBackendId);

  

  const [currentEmail, setCurrentEamil] = useState(getUserEmail().trim())
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(()=>{
    console.log(currentEmail, testEmails.includes(currentEmail))
  }, currentEmail)

  useEffect(()=>{
    setExpert(location?.state?.expert || dummyExperts[0])
  }, [expertId])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  useEffect(() => {
    console.log(data)
    scrollToBottom();
    //don't render the audio if muted in order to save on api usage
    //data.data only exists when the message is done typing
    if(data && data[data.length-1].role === 'assistant' && (data.length === 1 || data[data.length-1].data)){
      setTextToTranscribe(data[data.length-1].message)
    }
    
      
  }, [data]);

  //use effect to allow for submitting transcibed audio
  //due to weird nature of handleSubmit
  useEffect(() => {
    if(submitAudio && prompt){
      handleSubmit(true)
      setSubmitAudio(false)
    }
  }, [prompt, submitAudio])

  useEffect(()=>{
    queryClient.setQueryData(["chatCompletion", expertBackendId],[{message: expert.intro || `Hi, I am ${expert.name}, a former ${expert.title}${expert.role ? `, and a ${expert.role}` : ""}.   How may I help you?`, role: "assistant"}])
  },[expertId, expertBackendId])

  const handleSubmit = (autoplay=false) => {
    if (prompt.trim() !== "") {
      mutate({ prompt, expertId: expertBackendId, "files": [selectedFile] });
      setPrompt("");
      setSelectedFile(null);
      inputFileRef.current.value = "";
      autoPlay.current = autoplay
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(false);
    }
  };

  const handleUploadClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  return (
    <BackgroundTemplate>
    <Box display="flex" sx={{ flexDirection: 'column', flexGrow:1, justifyContent:'center', alignItems:'center', maxWidth:'100%'}}>
      <SourcesModal data={sourcesOpen} onClose={() => setSourcesOpen(false)} />
      <Box sx={{flexGrow: 1, display:'flex', justifyContent:'center', flexDirection:'column', width:{md:'80rem', xs:'100%', maxWidth:'100%'}}}>
      <Box sx={{marginTop:'0.5rem', width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', paddingX:'2%'}}>
          <Grid container sx={{display:'flex', justifyContent:'center', alignItems:'center'}} columnSpacing={1}>
            <Grid item container sx={{height:'5rem', width:'4.5rem'}}>
              <img
                  src={logo}
                  alt="Expert"
                  style={{ width: '100%', height: '100%', borderRadius: 12 }}
                  />
            </Grid>
            <Grid item>
              <Typography sx={{fontFamily: Anta.fontFamily, fontSize:fontSizes.subtitleSmall, color: theme.palette.primary.main}}>Sinsen</Typography>
              </Grid>
              
            </Grid>
          </Box>
          <Box sx={{width:'8rem', display:'flex', alignItems:'flex-end'}}>
          <DownloadChatButton data={data} text="PDF"/>
          </Box>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'row', justifyContent:'center',  width:'100%', }}>
        <Box sx={{width:'100%', display:'flex',}}>   
            <Box display="flex" alignItems="center" flexDirection="column" overflow="hidden" width="100%" height="100%">
              <Box sx={{overflowY: 'auto', width: '100%', display: 'flex', flexGrow: 1, paddingTop:{xs:0, md:'1rem'}}}> 
                <Box display="flex" justifyContent="center" width="100%" paddingX="2%">
                  <Box sx={{width:'100%'}}>
                    {data?.map((chat, i) => (
                      <ChatMessage key={i} data={chat} setSourcesOpen={setSourcesOpen} />
                    ))}
                    <div ref={messagesEndRef} />
                  </Box>
                </Box>
              </Box>
              
              {selectedFile && (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" sx={{ mr: 1 }}>
                      {selectedFile.name}
                    </Typography>
                    <IconButton onClick={() => setSelectedFile(null)}>
                      <Clear />
                    </IconButton>
                  </Box>
                )}
              <Box display="flex" alignItems="center" mb={2} mt={2} width="100%" sx={{flexDirection:'column', display:'flex'}}>
                <Box sx={{flexDirection:{xs:'column', md:'row'}, width:'100%', display:'flex', }}>
                <TextField
                  sx={{background:theme.palette.background.paper}}
                  autoComplete="off"
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Ask the expert..."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyDown={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <>
                      <IconButton onClick={handleUploadClick} color="primary" size="large">
                        <UploadFile/>
                      </IconButton>
                      <input
                        key={selectedFile}
                        type="file"
                        ref={inputFileRef}
                        style={{ display: 'none' }} // Hide the input element
                        onChange={handleFileChange}
                      />
                      <Box sx={{justifyContent:'center', alignItem:'center', display:'flex'}}>
                        <RecordButton onTranscribe={(t) => {setPrompt(t); setSubmitAudio(true); autoPlay.current = true}}/>
                      </Box>
                      </>
                    ),
                    endAdornment: isPending ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton onClick={() => handleSubmit(false)} color="primary" size="large">
                        <ArrowUpward />
                      </IconButton>
                    ),
                  }}
                />
                <Box sx={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', display:'flex'}}>
                    <IconButton onClick={() => setAduioMuted(!audioMuted)} sx={{color:audioMuted ? theme.palette.error.main : theme.palette.primary.main}}>
                    {audioMuted ? 
                      <Tooltip title="unmute audio" placement='top'>
                      <VolumeOffIcon color={theme.palette.error.main}/> 
                      </Tooltip>
                      :
                      <Tooltip title="mute audio" placement='top'>
                        <VolumeUpIcon color={theme.palette.primary.main}/>
                      </Tooltip>
                    }
                    </IconButton>
                  <TextToSpeechPlayer voiceId={expert.voice} textToPlay={textToTranscribe} muted={audioMuted} autoplay={Boolean(autoPlay.current) && !audioMuted}/>
                </Box>
                </Box>
              </Box>
              </Box>
            </Box>
        </Box>
      </Box>
      
    </Box>
    </BackgroundTemplate>
  );
}

export default ChatBot;
